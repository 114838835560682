<template>
  <div>
    <div
      class="row align-items-center my-2"
      v-for="(image, i) in images"
      :key="i"
    >
      <div class="col-2">
        <img class="img-upload-view" :src="image.uri" alt="" />
      </div>
      <div class="col-8">
        <div class="line-1">
          <small class="text-warning mr-2"
            >({{ (image.size / 1000).toFixed(1) }} KB) </small
          >{{ image.name }}
        </div>
        <div class="progress m-b-sm mt-1" style="height: 2px">
          <div
            class="progress-bar"
            role="progressbar"
            style="width: 100%"
            aria-valuenow="100"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
      </div>
      <div class="col-1">
        <i
          @click="removeImage = { status: true, index: i }"
          class="text-danger material-icons-outlined"
          >close</i
        >
      </div>
    </div>
    <div class="text-right">
      Total File Size: {{(totalImageFileSize / 1000).toFixed(1)}} KB
    </div>
    <modal
      title="Confirm"
      v-model="removeImage.status"
      okText="Yes, Remove it."
      @ok="SET_REMOVE_IMAGE"
    >
      Do you want to remove image?
    </modal>
  </div>
</template>

<script>
import Modal from "../../../../ui/modal.vue";
export default {
  components: {
    Modal,
  },
  data() {
    return {
      removeImage: {
        status: false,
        index: -1,
      },
    };
  },
  props: {
    images: {
      default: () => [],
    },
    totalImageFileSize: {
      default: 0
    },
  },
  methods: {
    SET_REMOVE_IMAGE() {
      this.$emit("remove:image", this.removeImage.index);
      this.removeImage.status = false;
    },
  },
};
</script>

<style>
.img-upload-view {
  width: 100%;
  height: 30px;
  border-radius: 12px;
  object-fit: cover;
}
.line-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-clamp: 1;
}
</style>