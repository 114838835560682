<template>
  <div>
    <div class="text-right my-4" v-if="!loading">
      <div class="row justify-content-end">
        <div class="col-12 my-2 col-md-4 col-xl-4 col-lg-3">
          <v-select
            ref="serialNumbers"
            :searchable="false"
            label="text"
            class="style-chooser"
            :value="activeSort"
            @option:selected="SET_SORT"
            :clearable="false"
            placeholder="Select Sort"
            :options="sorts"
          >
          </v-select>
        </div>
      </div>
      <h6>
        <span class="text-info">
          <i>{{ allItemsCount }} reports found</i>
        </span>
      </h6>
    </div>
    <div class="d-flex justify-content-end w-100">
      <CardTable
        :allItemsCount="allItemsCount"
        :loading="loading"
        :listOfReports="reports"
        :page="page"
        :pages="pages"
        @change="SET_PAGINATION"
        @export:pdf="GET_REPORT_DETAIL_THEN_EXPORT_PDF"
      />
    </div>
    <PdfLoadingModal :loadingDetailModal="loadingDetailModal"></PdfLoadingModal>
  </div>
</template>

<script>
import ExportPdf from "../Exports/exportPDF";
import CardTable from "../ui/table/cardTableReports.vue";
import { dataFormatterNaApiWithTime } from "../../plugins/date-formatter";
import PdfLoadingModal from "../shared/modal/PdfLoadingModal.vue";
export default {
  components: {
    CardTable,
    PdfLoadingModal,
  },
  mixins: [ExportPdf],
  props: {
    companyId: {
      default: -1,
    },
  },
  data() {
    return {
      activeSort: { value: "date-desc", text: "Sort by Date (Z-A)" },
      sorts: [
        { value: "date-asc", text: "Sort by Date (A-Z)" },
        { value: "date-desc", text: "Sort by Date (Z-A)" },
        { value: "brand-asc", text: "Sort by Brand Name (Z-A)" },
        { value: "brand-desc", text: "Sort by Brand Name (Z-A)" },
      ],
      loading: true,
      reports: [],
      allItemsCount: 0,
      page: 1,
      limit: 10,
      headers: [
        { label: "Created At" },
        { label: "Company Name" },
        { label: "Product" },
        { label: "Technician Name" },
        { label: "Report Type" },
      ],
    };
  },
  computed: {
    pages() {
      return Math.ceil(this.allItemsCount / 10);
    },
  },
  methods: {
    SET_PAGINATION(page) {
      this.$router.replace({ query: { page } });
      this.page = page;
      this.GET_REPORT_LIST_WITHOUT_FILTER();
    },
    SET_SORT(e) {
      this.activeSort = e;
      this.SET_PAGINATION(1);
    },
    SET_DATE(date) {
      return dataFormatterNaApiWithTime(date);
    },
    SET_DETAIL_THEN_PUSH(row) {
      this.$router.push(`/report-detail/${row.id}`);
    },
    async GET_REPORT_LIST_WITHOUT_FILTER(isExcell = 0, isCompleted = 1) {
      this.loading = true;
      const response = await this.$api.get(
        `CompanyReports/ByCompany?companyId=${this.companyId}&isExcel=${isExcell}&sortBy=${this.activeSort.value}&offset=${this.page}&limit=${this.limit}&isCompleted=${isCompleted}`
      );
      if (response.result === "OK" && response.message === "OK") {
        this.reports = response.data.items;
        this.allItemsCount = response.data.count;
        this.loading = false;
      } else this.reports = [];
      this.loading = false;
    },
  },
  mounted() {
    if (this.$route.query.page) this.page = Number(this.$route.query.page);
    this.GET_REPORT_LIST_WITHOUT_FILTER();
  },
};
</script>

<style>
</style>