<template>
  <transition
    enter-active-class="fade"
    mode="out-in"
    leave-active-class="fade-out"
  >
    <div
      id="modal"
      class="modal centered"
      @keydown.esc="noClose ? '' : $emit('input', false)"
      @click="SET_CONTROL_ELEMENT"
      :class="{ show: value, 'modal-background': value }"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        id="content"
        :class="{ 'modal-dialog-lg': width === 'lg' }"
        class="modal-dialog modal-dialog-centered"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>
            <button
              type="button"
              @click="$emit('input', false)"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i class="material-icons">close</i>
            </button>
          </div>
          <div class="modal-body  has-scroll">
            <slot />
          </div>
          <div class="modal-footer" v-if="!hideFooter">
            <button
              @click="
                $emit('input', false);
                (e) => $emit('close', e);
              "
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              {{ closeText }}
            </button>
            <button
              @click="HANDLE_SUBMIT"
              type="button"
              :disabled="dataLoading"
              class="btn btn-primary"
            >
              <span
                v-if="dataLoading"
                class="spinner-border spinner-border-sm mr-2"
                role="status"
                aria-hidden="true"
              ></span>
              {{ okText }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      dataLoading: false,
    };
  },
  props: {
    width: {
      default: "",
    },
    value: {
      default: false,
    },
    title: {
      default: "",
    },
    noClose: {
      default: false,
      type: Boolean,
    },
    hideFooter: {
      default: false,
      type: Boolean,
    },
    okText: {
      default: "OK",
    },
    closeText: {
      default: "Close",
    },
  },
  watch: {
    value() {
      this.$eralp.ToggleButton("modal-open");
    },
  },
  methods: {
    SET_HANDLE_LOADING(params = true) {
      this.dataLoading = params;
    },
    SET_CONTROL_ELEMENT(e) {
      if (this.noClose) return;
      if (e.target.id === "modal") this.$emit("input", false);
    },
    HANDLE_SUBMIT(e) {
      this.$emit("ok", e, this.SET_HANDLE_LOADING);
    },
  },
};
</script>

<style>
.modal-background {
  background: rgba(0, 0, 0, 0.455) !important;
  display: block;
}
.has-scroll {
  overflow: scroll;
  max-height: 70vh;
}
.modal-dialog-lg {
  max-width: 1200px !important;
}
</style>