<template>
  <div>
    <div class="text-center" v-if="loading">
      <span class="spinner-border mr-2" role="status" aria-hidden="true"></span>
      <div><span class="ml-2">Loading...</span></div>
    </div>
    <div v-else class="row justify-content-center my-4">
      <div
        class="col-12 text-center"
        v-if="reportDetail.mailSentAt !== '0001-01-01T00:00:00'"
      >
        <div class="text-center">
          <i
            style="font-size: 72px"
            class="material-icons-outlined text-primary"
            >check</i
          >
          <p class="text-center mt-4">
            Service report was sent on
            <strong v-if="!loading" class="text-primary mx-2">{{
              SET_DATE(reportDetail.mailSentAt)
            }}</strong>
            to emails.
          </p>
        </div>
      </div>
      <div class="col-12 col-md-12 col-lg-10 col-xl-10 text-right my-4">
        <button @click="SET_PDF_FOR_THE_EMAIL" :class="`btn btn-warning mx-2`">
          {{ "Send Report to Email" }}
        </button>
      </div>
      <div class="col-12 col-md-12 col-lg-10 col-xl-10 text-right">
        <button
          @click="SET_UPDATE_REPORT_THEN_PUSH"
          :class="`btn btn-${
            reportDetail.isCompleted === 0 ? 'success' : 'warning'
          } mx-2`"
        >
          Update Report
          {{ reportDetail.isCompleted === 0 ? "For Complete" : "" }}
        </button>
        <button
          @click="SET_EXPORT_PDF(reportDetail)"
          class="btn btn-danger mx-2"
        >
          Export to PDF
        </button>
      </div>
    </div>
    <div v-if="!loading" class="row justify-content-center align-items-start">
      <div class="col-12 col-md-6 col-lg-5 col-xl-5">
        <img
          class="d-inline d-lg-none"
          src="/images/logo.png"
          width="100%"
          alt=""
        />
        <div class="card">
          <div class="card-body">
            <div class="card-title">Report</div>
            <span
              class="btn d-flex"
              :class="{
                'btn-info': reportDetail.report.id % 4 === 1,
                'btn-success': reportDetail.report.id % 4 === 2,
                'btn-danger': reportDetail.report.id % 4 === 3,
                'btn-warning': reportDetail.report.id % 4 === 0,
              }"
            >
              <i class="mr-2 material-icons-outlined">summarize</i>

              {{ reportDetail.report.name }}
            </span>
            <div class="card-title mt-4">Technician Name</div>
            <div>
              <span
                style="font-size: 13px !important"
                class="d-flex btn btn-warning d-block align-items-center"
              >
                <i class="mr-2 material-icons-outlined">person</i>
                {{ reportDetail.technicianName }}
              </span>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="card-title mt-4">Arrival Time</div>
                <div>
                  {{ SET_DATE(reportDetail.arrivalTime) }}
                </div>
              </div>
              <div class="col-6">
                <div class="card-title mt-4">Depart Time</div>
                <div>
                  {{ SET_DATE(reportDetail.departTime) }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="card-title">Company</div>
            <p>
              <strong class="text-primary mr-2">Name:</strong>
              {{ reportDetail.company.name }}
            </p>
            <p>
              <strong class="text-primary mr-2">Address:</strong>
              {{ reportDetail.company.address }}
            </p>
            <p>
              <strong class="text-primary mr-2">Email:</strong>
              {{ reportDetail.company.email }}
            </p>
            <p>
              <strong class="text-primary mr-2">Phone:</strong>
              {{ reportDetail.company.phone }}
            </p>
            <p>
              <strong class="text-primary mr-2">Postcode:</strong>
              {{ reportDetail.company.postcode }}
            </p>
            <p>
              <strong class="text-primary mr-2">State:</strong>
              {{ reportDetail.company.state }}
            </p>
            <p>
              <strong class="text-primary mr-2">Company Note:</strong>
              {{ reportDetail.company.note }}
            </p>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="card-title">Product</div>
            <p>
              <strong class="text-primary mr-2">Brand Name:</strong>
              {{ reportDetail.product.brand.name }}
            </p>
            <p>
              <strong class="text-primary mr-2">Product Model:</strong>
              {{ reportDetail.product.model }}
            </p>
            <p>
              <strong class="text-primary mr-2">Serial No:</strong>
              {{ reportDetail.product.companyProduct.serialNo }}
            </p>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              Images ({{ reportDetail.images.length }})
            </div>
            <div class="row" v-if="reportDetail.images.length !== 0">
              <div
                class="col-lg-4 col-6 my-2 position-relative"
                v-for="(image, i) in reportDetail.images"
                :key="i"
              >
                <span
                  class="close-badge"
                  @click="removeImage = { status: true, id: image.id }"
                >
                  <i class="material-icons-outlined">close</i>
                </span>
                <img
                  :id="'image-' + i"
                  @click="
                    imageIndex = i;
                    visible = true;
                  "
                  :src="SET_IMAGE(image)"
                  class="shadow thumbnail-detail-image"
                  alt="image"
                />
              </div>
              <div class="mt-4 col-12 text-right">
                <button
                  @click="$refs.fileInput.click()"
                  class="btn btn-success"
                  :disabled="
                    10 - reportDetail.images.length - images.length < 1
                  "
                >
                  + Add Image ({{
                    10 - reportDetail.images.length - images.length
                  }})
                </button>
              </div>
            </div>
            <div class="text-center" v-else>
              <p><i>No Image</i></p>
              <div class="mt-4">
                <button
                  @click="$refs.fileInput.click()"
                  class="btn btn-primary"
                >
                  + Add Image ({{ 10 - images.length }})
                </button>
              </div>
            </div>
            <input
              type="file"
              ref="fileInput"
              accept="image/*"
              multiple="true"
              class="d-none"
              @change="SET_UPLOAD_IMAGE"
            />
            <div class="mt-4" v-if="images.length !== 0">
              <ReportImages :images="images" @remove:image="SET_REMOVE_IMAGE" />
              <div
                class="alert"
                v-if="alertImage.status"
                :class="`alert-${alertImage.variant}`"
              >
                {{ alertImage.message }}
              </div>
              <div class="mt-4 text-right">
                <button
                  @click="SEND_UPLOAD_IMAGE_TO_REPORT"
                  :disabled="loadingUploadImage"
                  class="btn btn-primary d-flex align-items-center"
                >
                  <span
                    v-if="loadingUploadImage"
                    class="spinner-border spinner-border-sm mr-2"
                    role="status"
                    aria-hidden="true"
                  ></span>

                  Upload Images
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="card-title">Comment</div>
            <p v-if="reportDetail.comment !== ''">
              {{ reportDetail.comment }}
            </p>
            <p class="text-center" v-else>
              <i>No comment</i>
            </p>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-5 col-xl-5">
        <div class="text-center">
          <img
            class="d-none d-lg-inline"
            src="/images/logo.png"
            width="60%"
            alt=""
          />
        </div>
        <div class="card">
          <div class="card-body" v-if="reportDetail.answers.length !== 0">
            <div v-for="(question, i) in reportDetail.answers" :key="i">
              <div class="card-title text-primary">
                <span class="mr-2">Q{{ i + 1 }}:</span> {{ question.question }}
              </div>
              <ol v-if="question.answers.length !== 0">
                <li
                  class="list"
                  v-for="(answer, j) in question.answers"
                  :key="i + '-' + j"
                >
                  {{ answer.value }}
                </li>
              </ol>

              <div v-else class="text-center">
                <i
                  style="font-size: 48px"
                  class="text-warning material-icons-outlined"
                  >warning_amber</i
                >
                <p>
                  <i>No answer of question</i>
                </p>
              </div>
            </div>
          </div>
          <div v-else class="text-center card-body">
            <div>
              <i
                style="font-size: 48px"
                class="text-warning material-icons-outlined"
                >warning_amber</i
              >
            </div>
            <p>
              <i>This report has not questions</i>
            </p>
          </div>
        </div>
      </div>
      <div class="card w-100">
        <div class="card-body">
          <div class="card-title my-4">
            Other Reports of {{ reportDetail.company.name }}
          </div>
          <ListOfReportByCompany :companyId="reportDetail.company.id" />
        </div>
      </div>
    </div>
    <Modal
      v-model="showIncompleteReportAlertModal"
      okText="I got it"
      @ok="showIncompleteReportAlertModal = false"
      title="Incomplete Report Warning"
    >
      <div class="text-center">
        <i
          style="font-size: 72px"
          class="mr-2 text-warning material-icons-outlined"
          >warning_amber</i
        >
        <p class="mt-4">
          The report you created on
          <strong class="text-primary mx-2">{{
            SET_DATE(reportDetail.createdAt)
          }}</strong>
          has not been completed yet.
          <strong> Please complete this report.</strong>
        </p>
      </div>
    </Modal>
    <Modal
      okText="I got it"
      @ok="showAlertImageModal = false"
      v-model="showAlertImageModal"
      title="Warning!"
    >
      You must upload a maximum of
      {{ 10 - images.length - reportDetail.images.length }} image{{
        10 - images.length - reportDetail.images.length === 1 ? "" : "s"
      }}
    </Modal>
    <modal
      title="Confirm"
      v-model="removeImage.status"
      okText="Yes, Remove it."
      @ok="(e, l) => SEND_UPLOAD_IMAGE_TO_REPORT(e, l, true)"
    >
      <div
        class="alert"
        v-if="alertImage.status"
        :class="`alert-${alertImage.variant}`"
      >
        {{ alertImage.message }}
      </div>
      Do you want to remove image?
    </modal>
    <VueEasyLightbox
      @hide="visible = false"
      :visible="visible"
      :index="imageIndex"
      :imgs="imgs"
    />
    <Modal
      okText="Send Report"
      @ok="SEND_PDF_TO_EMAIL"
      v-model="emailSendModal"
      ref="sendPdf"
      title="Send Report to Company with email"
    >
      <div
        class="alert"
        v-if="alertImage.status"
        :class="`alert-${alertImage.variant}`"
      >
        {{ alertImage.message }}
      </div>
      <div v-if="isLoadingEmailSend" class="text-center">
        <span
          class="spinner-border mr-2"
          role="status"
          aria-hidden="true"
        ></span>
        <p>Loading...</p>
      </div>
      <div v-else-if="reportDetail.mailSentAt === '0001-01-01T00:00:00'">
        <div v-if="emailSendModal" class="form-group">
          <label for="taxno">to:</label>
          <input
            class="form-control"
            id="taxno"
            maxlength="50"
            v-model="reportDetail.company.email"
            required
            disabled
            aria-describedby="emailHelp"
            placeholder="Enter email"
          />
        </div>
        <div class="form-group">
          <label for="taxno">cc:</label>
          <input
            class="form-control"
            id="taxno"
            maxlength="50"
            v-model="getActiveUser.email"
            required
            disabled
            aria-describedby="emailHelp"
            placeholder="Enter email"
          />
        </div>
      </div>
      <div v-else>
        <div class="text-center">
          <i
            style="font-size: 72px"
            class="material-icons-outlined text-primary"
            >check</i
          >
          <p class="text-center mt-4">
            Service report was sent on
            <strong v-if="!loading" class="text-primary ml-2">{{
              SET_DATE(reportDetail.mailSentAt)
            }}</strong
            >.
          </p>
        </div>
        <div class="card-title">
          If you want to email again, you can select email below to that you
          want to send the email.
        </div>
        <div class="my-4">
          <v-select
            :searchable="false"
            label="text"
            class="style-chooser"
            :value="activeMail"
            @option:selected="SET_ACTIVE_MAIL"
            :clearable="false"
            placeholder="Select Email Address"
            :options="emailAddresses"
          >
          </v-select>
        </div>
      </div>
      <div class="form-group">
        <label for="taxno">Email Content:</label>
        <textarea
          class="form-control"
          id="taxno"
          maxlength="500"
          rows="6"
          v-model="email.content"
          aria-describedby="emailHelp"
          placeholder="Enter email content"
        />
      </div>
      <!-- <div v-if="email.status" class="mt-4">
        <div class="card-title">PDF FILE</div>
        <div class="card w-50">
          <div
            @click="SET_EXPORT_PDF(reportDetail)"
            class="card-body text-center"
          >
            <i
              style="font-size: 72px"
              class="text-primary material-icons-outlined"
              >attach_file</i
            >
            <p>
              <a style="font-size: 10px" href="javascript:void(0)"
                >{{ reportDetail.product.companyProduct.serialNo }}-{{
                  SET_DATE(reportDetail.createdAt)
                }}.pdf</a
              >
            </p>
          </div>
        </div>
      </div>
      <div v-else class="text-center mt-4">
        <span
          class="spinner-border mr-2"
          role="status"
          aria-hidden="true"
        ></span>
        <div>
          <span class="ml-2">Pdf file is uploading... Please Wait</span>
          <div>{{ uploadedImageCount }} / {{ reportDetail.images.length }}</div>
        </div>
      </div> -->
    </Modal>
    <Modal hide-footer v-model="isMobileDownloadStarted.status">
      <div class="card-body">
        <div class="text-center">
          <div v-if="isMobileDownloadStarted.loading" class="text-center">
            <span
              class="spinner-border mr-2"
              role="status"
              aria-hidden="true"
            ></span>
            <p>Loading...</p>
          </div>
          <i
            v-else-if="isMobileDownloadStarted.process"
            style="font-size: 72px"
            class="material-icons-outlined text-primary"
            >download</i
          ><i
            v-else
            style="font-size: 72px"
            class="material-icons-outlined text-primary"
            >check</i
          >
          <p
            class="text-center mt-4"
            v-html="isMobileDownloadStarted.message"
          ></p>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { dataFormatterNaApiWithTime } from "../../plugins/date-formatter";
import ListOfReportByCompany from "./listOfReportbyCompany.vue";
import VueEasyLightbox from "vue-easy-lightbox";
import Modal from "../ui/modal.vue";
import ExportPdf from "../Exports/exportPDF";
import ReportImages from "../createReport/chooseCompany/chooseProduct/questions/reportImages.vue";
export default {
  mixins: [ExportPdf],
  components: {
    Modal,
    VueEasyLightbox,
    ReportImages,
    ListOfReportByCompany,
  },
  data() {
    return {
      isLoadingEmailSend: true,
      activeMail: { value: 1, text: "Select email address" },
      emailAddresses: [],
      emailSendModal: false,
      email: {
        content: "",
        file: [],
        status: false,
      },
      loading: true,
      reportDetail: {
        createdAt: "",
        images: [],
      },
      showIncompleteReportAlertModal: false,
      visible: false,
      imageIndex: 0,
      showAlertImageModal: false,
      images: [],
      removeImage: {
        status: false,
        id: -1,
      },
      loadingUploadImage: false,
      alertImage: {
        status: false,
        message: "",
        variant: "success",
      },
    };
  },
  computed: {
    imgs() {
      return this.reportDetail.images.map((i) => this.$api.BASE_URL + i.path);
    },
    getActiveUser() {
      return this.$store.state.user.activeUser;
    },
  },
  methods: {
    SET_ACTIVE_MAIL(row) {
      this.activeMail = row;
    },
    SET_PDF_FOR_THE_EMAIL() {
      this.emailSendModal = true;
      this.isLoadingEmailSend = true;
      this.alertImage.status = false;
      this.$refs.sendPdf.SET_HANDLE_LOADING();
      setTimeout(() => {
        // this.SET_EXPORT_PDF(this.reportDetail, true);
        this.email.content = `The ${this.reportDetail.report.name} created by ${
          this.reportDetail.technicianName
        } on ${this.SET_DATE(this.reportDetail.createdAt)} is linked below.
        `;
        this.$refs.sendPdf.SET_HANDLE_LOADING(false);
      }, 50);
      this.isLoadingEmailSend = false;
    },
    SET_URL() {
      return location.host + `/#/external-report?id=${this.reportDetail.id}`;
    },
    async SEND_PDF_TO_EMAIL(e, loading) {
      // if (!this.email.status) return;

      this.alertImage.status = false;
      loading();
      const fd = new FormData();
      fd.append("CompanyReportId", this.reportDetail.id);
      fd.append(
        "Content",
        this.email.content +
          `        <div style='margin-top:15px'>
        <a href="http://${this.SET_URL()}" target="_blank" style='font-size:24px'>Click to view the report</a>
        </div>`
      );
      // fd.append("ReportFile", this.email.file);
      this.$store.commit("SET_PDF_IS_SENDING", {
        isSending: true,
        isShow: true,
        text: "Mail is sending...",
      });
      this.$api
        .post(`CompanyReports/SendMail?toCompany=${this.activeMail.value}`, fd)
        .then((response) => {
          if (response.result === "OK" && response.message === "OK") {
            this.alertImage = {
              status: true,
              message: "Pdf has been sent with succesfully.",
              variant: "success",
            };
            this.$store.commit("SET_PDF_IS_SENDING", {
              isSending: false,
              isShow: true,
              text: "Pdf has been sent with succesfully.",
            });
            setTimeout(() => {
              this.reportDetail.mailSentAt = new Date().toISOString();
              this.emailSendModal = false;
              this.$store.commit("SET_PDF_IS_SENDING", {
                isSending: false,
                isShow: false,
                text: "Pdf has been sent with succesfully.",
              });
            }, 2500);
          } else {
            this.alertImage = {
              status: true,
              message: "Pdf has not been sent. Please Try Again.. ",
              variant: "danger",
            };
            this.$store.commit("SET_PDF_IS_SENDING", {
              isSending: false,
              isShow: true,
              text: "Mail has not been sent. Please Try Again.",
            });
          }
        });
      loading(false);
      this.emailSendModal = false;
    },
    async SEND_UPLOAD_IMAGE_TO_REPORT(
      e,
      loading = () => false,
      remove = false
    ) {
      loading();
      this.alertImage.status = false;
      this.loadingUploadImage = true;
      const fd = new FormData();
      if (!remove) {
        const images = this.images.map((i) => i.file);
        for (let i = 0; i < images.length; i++) {
          const image = images[i];
          fd.append("NewImages", image);
        }
      }
      if (remove) fd.append("RemovedImages", this.removeImage.id);
      const response = await this.$api.put(
        `CompanyReports/UpdateImages?id=${this.reportDetail.id}`,
        fd
      );
      if (response.result === "OK" && response.message === "OK") {
        this.alertImage = {
          status: true,
          message: "Images saved with successfully.",
          variant: "success",
        };
        setTimeout(() => {
          this.$router.go(0);
        }, 2500);
      } else {
        this.alertImage = {
          status: true,
          message: "Something went wrong. Please try again.",
          variant: "danger",
        };
      }
      this.loadingUploadImage = false;
      loading(false);
    },
    SET_REMOVE_IMAGE(index) {
      this.images.splice(index, 1);
    },
    async SET_UPLOAD_IMAGE(e) {
      this.showAlertImageModal = false;
      const images = e.target.files;
      if (
        this.images.length + this.reportDetail.images.length + images.length >
        10
      )
        return (this.showAlertImageModal = true);
      for (let i = 0; i < images.length; i++) {
        const image = images[i];
        const response = await this.$eralp.createImage(image);
        this.images.push({ ...response, size: image.size, name: image.name });
      }
    },
    SET_IMAGE(image) {
      return this.$api.BASE_URL + image.path;
    },
    SET_DATE(date) {
      if (date.includes("T")) return dataFormatterNaApiWithTime(date);
      else return "";
    },
    SET_UPDATE_REPORT_THEN_PUSH() {
      this.$store.commit("SET_EDIT_REPORT_ID", this.reportDetail.id);
      this.$store.commit("SET_UPDATE_REPORT", {
        key: "serialNo",
        data: {
          id: this.reportDetail.product.companyProduct.id,
          serialNo: this.reportDetail.product.companyProduct.serialNo,
          status: true,
        },
      });
      this.$store.commit("SET_UPDATE_REPORT", {
        key: "product",
        data: {
          id: this.reportDetail.product.id,
          model: this.reportDetail.product.model,
          status: true,
        },
      });
      this.$store.commit("SET_UPDATE_REPORT", {
        key: "company",
        data: { status: true, ...this.reportDetail.company },
      });
      this.$store.commit("SET_UPDATE_REPORT", {
        key: "questions",
        data: { status: true, data: this.reportDetail.answers },
      });
      this.$store.commit("SET_UPDATE_REPORT", {
        key: "comment",
        data: this.reportDetail.comment,
      });
      this.$store.commit("SET_UPDATE_REPORT", {
        key: "time",
        data: {
          arrivalTime: this.reportDetail.arrivalTime,
          departTime: this.reportDetail.departTime,
        },
      });
      this.$store.commit("SET_UPDATE_REPORT", {
        key: "brand",
        data: {
          id: this.reportDetail.product.brand.id,
          name: this.reportDetail.product.brand.name,
          status: true,
        },
      });
      this.$router.push(
        `/update-report/${this.reportDetail.report.id}/product`
      );
    },
    async GET_REPORT_DETAIL() {
      this.loading = true;
      const response = await this.$api.get(
        `CompanyReports/${this.$route.params.id}`
      );
      if (response.result === "OK" && response.message === "OK") {
        this.reportDetail = response.data;
        this.emailAddresses.push(
          {
            text: "Me: " + this.getActiveUser.email,
            value: 0,
          },
          {
            text: "Company: " + response.data.company.email,
            value: 1,
          }
        );
        if (response.data.isCompleted === 0)
          this.showIncompleteReportAlertModal = true;
      } else this.$router.push("/");
      this.loading = false;
      // this.SET_EXPORT_PDF(this.reportDetail, true);
    },
  },
  mounted() {
    this.GET_REPORT_DETAIL();
  },
};
</script>

<style>
.list {
  list-style: none;
  font-weight: 700;
  line-height: 1.9;
}
.thumbnail-detail-image {
  width: 100%;
  height: 100px;
  object-fit: cover;
  border-radius: 13px;
  cursor: pointer;
}
.close-badge {
  position: absolute;
  right: 5px;
  top: -10px;
  background: red;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  z-index: 5;
}
.close-badge:hover {
  transform: scale(1.1);
}
</style>