<template>
  <div class="w-100">
    <div class="d-flex justify-content-end align-items-center">
      <eralp-pagination
        :page="page"
        :pages="pages"
        @change="(e) => $emit('change', e, true)"
      />
    </div>
    <div class="d-none d-lg-flex bg-primary text-white card row p-3 flex-row">
      <div
        class="
          col-12 col-md-6
          d-flex
          flex-column
          justify-content-center
          col-lg-3 col-xl-2
        "
      >
        Type
      </div>
      <div
        class="
          col-12 col-md-6
          d-flex
          flex-column
          justify-content-center
          col-lg-3 col-xl-2
        "
      >
        Date
      </div>
      <div
        class="
          col-12 col-md-6
          d-flex
          flex-column
          justify-content-center
          col-lg-3 col-xl-2
        "
      >
        Company
      </div>
      <div
        class="
          col-12 col-md-6
          d-flex
          flex-column
          justify-content-center
          col-lg-3 col-xl-2
        "
      >
        Product
      </div>
    </div>
    <div v-if="loading">
      <div class="text-center">
        <span
          class="spinner-border spinner-border-sm mr-2"
          role="status"
          aria-hidden="true"
        ></span>
        List of Report is Loading...
      </div>
    </div>
    <div v-else-if="listOfReports.length === 0" class="card text-center">
      <div class="card-body">
        <div class="my-4">
          <i
            style="font-size: 48px"
            class="text-warning material-icons-outlined"
            >warning_amber</i
          >
        </div>
        <p><i>There are no reports </i></p>
      </div>
    </div>
    <div v-else>
      <router-link
        :to="`/report-detail/${row.id}`"
        v-for="(row, i) in listOfReports"
        :key="i"
      >
      <div
        class="
          card
          row
          cursor-pointer
          p-3
          d-flex
          flex-row
          justify-content-sm-end justify-content-xl-start
        "
      >
        <span
          class="position-absolute bg-primary px-2 text-white"
          style="top: 0; left: 0px; border-radius: 10px 0 3px 0"
          >{{ allItemsCount - ((page - 1) * 10 + i) }}.</span
        >
        <div
          class="
            col-12
            my-2
            col-md-4
            d-flex
            flex-column
            justify-content-center
            col-lg-3 col-xl-2
          "
        >
          <span
            class="
              badge
              btn-block
              d-flex
              align-items-center
              justify-content-center
            "
            :class="{
              'badge-info': row.report.id % 4 === 1,
              'badge-success': row.report.id % 4 === 2,
              'badge-danger': row.report.id % 4 === 3,
              'badge-warning': row.report.id % 4 === 0,
            }"
          >
            <i class="mr-2 material-icons-outlined">summarize</i>

            {{ row.report.name }}
          </span>
        </div>
        <div
          class="
            col-6
            my-2
            col-md-8
            d-flex
            flex-column
            justify-content-center
            col-lg-3 col-xl-2
          "
        >
          <strong class="text-primary">{{ SET_DATE(row.createdAt) }}</strong>
          <div class="mt-2">
            <span
              class="
                d-flex
                text-warning
                justify-content-start
                align-items-center
              "
            >
              <i class="mr-2 material-icons-outlined">person</i>
              {{ row.technicianName }}
            </span>
          </div>
        </div>
        <div
          class="
            col-6
            my-2
            col-md-4
            d-flex
            flex-column
            justify-content-center
            col-lg-3 col-xl-2
          "
        >
          <div class="d-lg-none"><strong>Company</strong></div>
          <router-link :to="`/companies/${row.company.id}`">
            <span class="text-primary">{{ row.company.name }}</span>
          </router-link>
        </div>
        <div
          class="
            col-12
            my-2
            col-md-8
            d-flex
            flex-column
            justify-content-center
            col-lg-3 col-xl-4
          "
        >
          <div>
            <strong class="d-lg-none mr-2">Product</strong>
            <div>
              <span class="badge mt-2 badge-info">
                <router-link
                  class="text-info"
                  :to="`/brands/${row.product.brand.id}`"
                  >{{ row.product.brand.name }}</router-link
                >
                - {{ row.product.model }}
              </span>

              <strong class="badge mx-2 badge-success">{{
                row.product.companyProduct.serialNo
              }}</strong>
            </div>
          </div>
        </div>
        <div
          class="
            col-12
            justify-content-end
            d-flex
            my-2
            col-md-6
            d-flex
            flex-column
            justify-content-center
            col-lg-3 col-xl-2
          "
        >
          <button
            @click="$emit('export:pdf', row.id)"
            class="btn btn-block justify-content-center d-flex btn-danger"
          >
            <i class="material-icons-outlined mr-2">picture_as_pdf</i>
            Export to PDF
          </button>
        </div>
      </div>
      </router-link>
    </div>
    <div class="d-flex justify-content-end align-items-center mb-5">
      <eralp-pagination
        :page="page"
        :pages="pages"
        @change="(e) => $emit('change', e, true)"
      />
    </div>
  </div>
</template>

<script>
import { dataFormatterNaApiWithTime } from "../../../plugins/date-formatter";
export default {
  props: {
    listOfReports: {
      default: () => [],
    },
    page: {
      default: 1,
    },
    pages: {
      default: 0,
    },
    loading: {
      default: true,
    },
    allItemsCount: {
      default: 0,
    },
  },
  methods: {
    SET_DETAIL_THEN_PUSH(row) {
      this.$router.push(`/report-detail/${row.id}`);
    },
    SET_DATE(date) {
      return dataFormatterNaApiWithTime(date);
    },
  },
};
</script>

<style>
</style>