<template>
  <Modal
    hide-footer
    v-model="loadingDetailModal"
    title="Report is exporting to PDF"
  >
    <div class="card-body">
      <div class="text-center">
        <span
          class="spinner-border mr-2 text-primary"
          role="status"
          aria-hidden="true"
        ></span>
        <div class="mt-4 text-primary">
          <strong> Creating pdf file... Please Wait.. </strong>
        </div>
        <div class="mt-4">
          <i>This process may take a while</i>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from "../../ui/modal.vue";

export default {
  components: { Modal },
  props: {
    loadingDetailModal: {
      default: false,
    },
  },
};
</script>

<style>
</style>